// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListLayout_clickableListLayout__sndlZ {
  [class~=ant-table-row] {
    cursor: pointer;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/ListLayout.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".clickableListLayout {\n  [class~=ant-table-row] {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableListLayout": `ListLayout_clickableListLayout__sndlZ`
};
export default ___CSS_LOADER_EXPORT___;
